@media screen and (min-width:768px) {
    .countdown-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap:25px;
        margin: 15px;
    }
    
    .time-box {
        background: #f72e6d;
        border-radius: 10px 30px;
        padding: 20px;
        text-align: center;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        height: 100px;
        width: 90px;
        color: white;
    }
    .time-box2 {
        background: #3db1e7;
        border-radius: 10px 30px;
        padding: 20px;
        text-align: center;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        height: 100px;
        width: 90px;
        color: white;
    }
    .time-box3{
        background: #dfc51c;
        border-radius: 10px 30px;
        padding: 20px;
        text-align: center;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        height: 100px;
        width: 90px;
        color: white;
    }
    .time-box4 {
        background: #224ddc;
        border-radius: 10px 30px;
        padding: 20px;
        text-align: center;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        height: 100px;
        width: 90px;
        color: white;
    }
    
     .number {
        font-size: 4rem;
        font-weight: bold;
        color:white;
    }
    
    .label {
        font-size: 1rem;
        font-weight: 400;
        color: white;
    }
    
}


@media screen and (max-width:768px) {
    .countdown-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap:10px;
        padding: 25px;
    }
    
    .time-box {
        background: #f72e6d;
        border-radius: 10px 30px;
        padding: 20px;
        text-align: center;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        height: 90px;
        width: 80px;
        color: white;
    }
    .time-box2 {
        background: #3db1e7;
        border-radius: 10px 30px;
        padding: 20px;
        text-align: center;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        height: 90px;
        width: 80px;
        color: white;
    }
    .time-box3{
        background: #dfc51c;
        border-radius: 10px 30px;
        padding: 20px;
        text-align: center;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        height: 90px;
        width: 80px;
        color: white;
    }
    .time-box4 {
        background: #224ddc;
        border-radius: 10px 30px;
        padding: 20px;
        text-align: center;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        height: 90px;
        width: 80px;
        color: white;
    }
    
     .number {
        font-size: 1rem;
        font-weight: bold;
        color:white;
    }
    
    .label {
        font-size: .7rem;
        font-weight: 400;
        color: white;
    }
    
    
    
}


.midSpn{
    font-size: 45px;
    color: white;
    margin-top: -35px;
}