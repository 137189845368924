/* Base Styles */
.banner-carousel .content-box .title {
    font-size: 2vw; /* Responsive font size */
    color: #faaC1D;
    font-weight: 500;
    margin-bottom: 2vw;
    opacity: 1;
    transition: all 500ms ease;
}

.banner-carousel .content-box h2 {
    font-size: 5vw; /* Responsive font size */
    line-height: 1.2em;
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 3vw;
    opacity: 1;
    transition: all 500ms ease;
}

.banner-carousel .content-box .info-list {
    margin-bottom: 1vw;
    opacity: 1;
    transition: all 500ms ease;
}

.banner-carousel .content-box .btn-box {
    opacity: 1;
    transform: translateY(10vw); /* Adjusted for responsiveness */
}

.slider {
    width: 100%;
    height: 90vh;
    background-size: cover;
    background-position: center;
    transition: height 0.3s ease;
}

.btn-boxx {
    opacity: 1;
    transform: translateY(10vw); /* Adjusted for responsiveness */
    text-align: center;
}

.btn-style-two {
    padding: 1vw 2vw;
    font-size: 1.6vw;
    line-height: 3vw;
    color: #ffffff;
    background-color: #faaC1d;
    border-radius: 0.6vw;
    text-align: center;
    z-index: 99;
}

/* Extra small screens */
@media screen and (max-width: 480px) {
    .slider {
        height: 40vh !important;
        background-size: cover;
        /* margin-top: -20vh; */
        padding: 30px;
    }
   
    .banner-carousel .content-box .title {
        font-size: 5vw;
        margin-bottom: 7vw;
    }
    .banner-carousel .content-box h2 {
        font-size: 14pw !important;
        margin-bottom: 10vw !important;
    }
    .vanue {
        font-size: 3.5vw;
    }
    .btn-style-two {
        padding: 2vw 5vw;
        font-size: 3vw;
        line-height: 4vw;
    }

    .vanue{
    font-size: 10px !important;
    margin: 30px;
    }

    .info-list{
      margin-bottom: 200px !important; 
      font-size: 10px !important;
    }

    .class-btn{
        margin-top: 350px !important;
    }

    .clss-ttl{
        margin-top: 200px !important;
    }
   
}

/* Small screens (mobile devices) */
@media screen and (max-width: 768px) {
    .slider {
        height: 40vh;
        background-size: cover;
        /* margin-top: -15vh; */
    }
    .content-box {
        /* margin-top: 15vh; */
    }
    .banner-carousel .content-box .title {
        font-size: 5vw;
        margin-bottom: 5vw;
    }
    .banner-carousel .content-box h2 {
        font-size: 8vw;
        margin-bottom: 7vw !important;
    }
    .vanue {
        font-size: 3.5vw;
    }
    .btn-style-two {
        padding: 2vw 5vw;
        font-size: 3vw;
        line-height: 4vw;
    }

    .info-list{
      margin-bottom: 100px !important; 
    }

    .class-btn{
        margin-top: 250px !important;
    }

    .clss-ttl{
        margin-top: 190px !important;
    }
}

/* Medium screens (1360x1080) */
@media screen and (max-width: 1360px) {
    .slider {
        height: 50vh;
        background-size: contain;
        background-repeat: no-repeat;
        /* margin-top: -10vh; */
    }
    .content-box {
        /* margin-top: -20vh !important; */
    }
    .banner-carousel .content-box .title {
        font-size: 4vw;
        margin-bottom: 5vw;
    }
    .banner-carousel .content-box h2 {
        font-size: 7vw;
        margin-bottom: 6vw;
    }
    .vanue {
        font-size: 3vw;
    }
    .btn-style-two {
        padding: 2vw 4vw;
        font-size: 2.5vw;
        line-height: 3.5vw;
    }
    .banner-carousel .content-box .btn-box {
        margin-top: -40px;
        opacity: 1;
        transform: translateY(10vw);
    }
    .class-list{
        margin-top: 140px !important;   
    }

    .class-btn{
        margin-top: 300px !important;
    }

    .clss-ttl{
        margin-top: 190px !important;
    }


}

/* Large screens (1980x1080) */
@media screen and (max-width: 1980px) {
    .slider {
        height: 100vh;
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 0;
    }
    .content-box {
        /* margin-top: -30vh !important; */
    }
    .banner-carousel .content-box .title {
        font-size: 2vw;
        margin-bottom: 1.5vw;
    }
    .banner-carousel .content-box h2 {
        font-size: 4vw;
        margin-bottom: 2.5vw;
    }
    .vanue {
        font-size: 2vw;
    }
    .btn-style-two {
        padding: 1.5vw 3vw;
        font-size: 1.5vw;
        line-height: 2vw;
    }
    .banner-carousel .content-box .btn-box {
        margin-top: -40px !important;
        opacity: 1;
        transform: translateY(10vw);
    }
    .class-list{
        margin-top: -30px !important;   
    }

    .class-btn{
        margin-top: -150px !important;
    }

    .clss-ttl{
        margin-top: -90px !important;
    }
}



/* Large screens (1980x1080) */
@media screen and (min-width: 1980px) {
    .slider {
        height: 100vh;
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 0;
    }
    .content-box {
        /* margin-top: -30vh !important; */
    }
    .banner-carousel .content-box .title {
        font-size: 2vw;
        margin-bottom: 1.5vw;
    }
    .banner-carousel .content-box h2 {
        font-size: 4vw;
        margin-bottom: 2.5vw;
    }
    .vanue {
        font-size: 2vw;
    }
    .btn-style-two {
        padding: 1.5vw 3vw;
        font-size: 1.5vw;
        line-height: 2vw;
    }
    .banner-carousel .content-box .btn-box {
        margin-top: -40px !important;
        opacity: 1;
        transform: translateY(10vw);
    }
    .class-list{
        margin-top: -30px !important;   
    }

    .class-btn{
        margin-top: -150px !important;
    }

    .clss-ttl{
        margin-top: -90px !important;
    }
}


.important-height {
    /* height: 40% !important; */
    max-width: 20% !important;
  }