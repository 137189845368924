/* .main-header {
    position: relative;
  }
   */
  .mobile-nav-toggler {
    display: none;
  }
  
  @media (max-width: 768px) {
    .main-menu .navbar-collapse {
      display: none !important;
    }
  
    .mobile-nav-toggler {
      display: block;
      cursor: pointer;
    }
  
    .mobile-menu {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      z-index: 1000;
      overflow-y: auto;
      transition: all 0.3s;
    }
  
    .mobile-menu.open {
      display: block;
    }
  
    .mobile-menu .close-btn {
      display: flex;
      justify-content: flex-end;
      padding: 20px;
    }
  
    .mobile-menu .menu-box {
      padding: 20px;
    }
  }
  